import client from "../config";

export const getUsersAPI = async ({ custID, userGroupID, userGroupName }) => {
  return await client.post("ListUsers", {
    custID,
    userGroupID,
    userGroupName,
  });
};

export const orderNewUserAPI = async (data) => {
  return await client.post("NewOrders", data);
};

export const modifyUserAPI = async (data) => {
  return await client.post("ModOrders", data);
};

export const blockUserAPI = async (data) => {
  return await client.post("BlockUser", data )
}

export const sendPinAPI = async (data) => {
  return await client.post("SendPin", data)
}

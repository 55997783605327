import { LoadingButton } from "@mui/lab";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { MenuProps } from "../../../utils/Constants";

const ProductsForm = ({ loading, onSubmit, autoFillValues, allProducts }) => {
  const [products, setProducts] = useState([]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    let newProducts = products.map((el) => el).join(", ");
    onSubmit(newProducts);
  };

  const handleOnChange = (e) => {
    const {
      target: { value },
    } = e;
    setProducts(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    if (autoFillValues) {
      setProducts(autoFillValues.split(", "));
    }
  }, [autoFillValues]);

  return (
    <Box>
      <form onSubmit={onSubmitForm}>
        <FormControl fullWidth className="user-form">
          <Select
            required
            displayEmpty
            id="products-multi-select"
            multiple
            value={products}
            input={<OutlinedInput />}
            placeholder={"Products"}
            onChange={handleOnChange}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value="">
              <em>Select Products</em>
            </MenuItem>
            {allProducts?.map((product) => (
              <MenuItem key={product.productID} value={product.productName}>
                {product.productName}
              </MenuItem>
            ))}
          </Select>
          <LoadingButton
            sx={{ marginTop: 4 }}
            loading={loading}
            loadingIndicator="Updating..."
            variant="contained"
            type="submit"
          >
            Update
          </LoadingButton>
        </FormControl>
      </form>
    </Box>
  );
};
export default ProductsForm;

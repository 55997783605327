import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import Loader from "../../components/Loader/Loader";
import logo from "../../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "../../context/AlertContext";
// import "./ChangePassword.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { changePasswordAPI } from "../../apis/auth/AuthApi";
import { getChangePasswordEmailBody } from "../../utils/EmailHelpers";
import moment from "moment";
import { emailCustomerAPI } from "../../apis/email/EmailApi";
import { handleTokenExpiry } from "../../utils/AuthHelpers";

const ChangePassword = () => {
  const [values, setValues] = useState({
    code: "",
    password: "",
    showPassword: false,
    confirm: "",
    showConfirm: false,
  });
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const onChange = (fieldName) => (event) => {
    setValues({ ...values, [fieldName]: event.target.value });
  };
  const handleClickShowPassword = (fieldName) => () => {
    setValues({
      ...values,
      [fieldName]: !values[fieldName],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onClick = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        if (values.code && values.password && values.confirm) {
          if (values.password !== values.confirm) {
            throw new Error("Passwords do not match.");
          }
          setLoading(true);
          let { empID, empName } = state;
          let response = await changePasswordAPI({
            otpToken: values.code,
            empID,
            empName,
            empPwd: values.password,
          });

          if (response && response.data.length > 0) {
            response = response.data[0];
            if (response?.statusID === 1) {
              setAlert({
                message: "Password changed successfully",
                type: "success",
              });
              const emailBody = await getChangePasswordEmailBody(
                empName,
                moment().format("llll")
              );
              await emailCustomerAPI(emailBody);
              navigate("/");
            } else throw new Error("Please enter the correct OTP code.");
          } else throw new Error();
        } else throw new Error("Please enter all fields");
      } catch (e) {
        setLoading(false);
        setAlert({
          type: "error",
          message:
            e?.message ??
            "Could not change your password, please make sure that you entered the correct OTP",
        });
        handleTokenExpiry(e.response, setAlert, navigate);
      }
    },
    [navigate, values, setAlert, state]
  );
  return (
    <div className="forgot-pwd">
      <Card className="forgot-pwd-box">
        {state.requestFrom !== "home" && (
          <img
            src={logo}
            alt={"Best Petrol & Diesel Pte. Ltd."}
            className={"logo"}
          />
        )}
        <Loader open={loading} />
        <form onSubmit={onClick}>
          <TextField
            id="verifyCode"
            placeholder="Enter OTP code from your email"
            variant="outlined"
            value={values.code}
            onChange={onChange("code")}
            className={"text-field"}
            size={"small"}
          />
          <br />
          <OutlinedInput
            id="password"
            placeholder="New Password"
            variant="outlined"
            value={values.password}
            onChange={onChange("password")}
            type={values.showPassword ? "text" : "password"}
            size={"small"}
            className={"text-field"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword("showPassword")}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <br />
          {/* <br /> */}
          <OutlinedInput
            id="confirm-password"
            placeholder="Confirm New Password"
            variant="outlined"
            value={values.confirm}
            onChange={onChange("confirm")}
            type={values.showConfirm ? "text" : "password"}
            size={"small"}
            className={"text-field"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword("showConfirm")}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <br />

          <Button
            type="submit"
            variant={"contained"}
            color={"primary"}
            className={"button"}
          >
            Change Password
          </Button>
        </form>
      </Card>
      {state.requestFrom !== "home" && (
        <Typography
          sx={{
            // position: "absolute",
            bottom: 8,
            left: "43vw",
            fontSize: 10,
            marginTop: 5,
          }}
        >
          
        </Typography>
      )}
    </div>
  );
};
export default ChangePassword;

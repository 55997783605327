import {
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Header from "../Header/Header";
import { ProtectedRoutes } from "../../routes/AppRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const drawerWidth = 240;

const SidebarNav = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const onClickMenuItem = (path) => {
    navigate(path);
  };
  const isActiveRoute = (path) => {
    return path === "/"
      ? location.pathname === path
      : location.pathname.includes(path);
  };
  const drawer = (
    <div>
      <Toolbar
        sx={{
          padding: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffff",
        }}
      >
        <img src={logo} alt={"logo"} width={"80%"} />
      </Toolbar>
      <Divider />
      <List>
        {ProtectedRoutes.map((route) =>
          !route.subpath ? (
            <ListItem key={route.title} disablePadding>
              <ListItemButton
                onClick={() => onClickMenuItem(route.path)}
                sx={{
                  backgroundColor: (theme) =>
                    isActiveRoute(route.path) ? theme.palette.primary.dark : "",
                }}
              >
                <ListItemIcon
                  sx={{ color: (theme) => theme.palette.background.default }}
                >
                  {route.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{ color: (theme) => theme.palette.background.default }}
                  primary={route.title}
                />
              </ListItemButton>
            </ListItem>
          ) : null
        )}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <CssBaseline />
      <Header toggleDrawer={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};
export default SidebarNav;

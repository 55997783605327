import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { MAX_DAILY_LIMIT, MAX_MONTHLY_LIMIT } from "../../../utils/Constants";

const LimitForm = ({
  loading,
  onSubmit,
  autoFillValues,
  label,
  monthlyLimit,
}) => {
  const [value, setValue] = useState("");
  const [showDailyLimit, setShowDailyLimit] = useState(false);

  const onSubmitForm = (e) => {
    e.preventDefault();
    onSubmit(value);
  };

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (autoFillValues) {
      setValue(autoFillValues);
      setShowDailyLimit(true);
    }
    if (autoFillValues === null || autoFillValues === "N.A")
      setShowDailyLimit(false);
  }, [autoFillValues, setShowDailyLimit]);

  const toggleDailyLimit = () => {
    if (showDailyLimit) setValue(null);
    setShowDailyLimit(!showDailyLimit);
  };

  return (
    <Box>
      <form onSubmit={onSubmitForm}>
        <FormControl fullWidth className="user-form">
          {label === "Daily Limit" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDailyLimit}
                  onChange={toggleDailyLimit}
                />
              }
              label="Set Daily Limit"
            />
          )}
          {((label === "Daily Limit" && showDailyLimit) ||
            label === "Monthly Limit") && (
            <TextField
              id="user-limit-field"
              label={label}
              variant="outlined"
              type={"number"}
              onChange={handleOnChange}
              value={value}
              required
              InputProps={{
                inputProps: {
                  min: 1,
                  // for monthly limit: 10000, for daily limit: less than equal to
                  //  monthly limit
                  max:
                  label === "Monthly Limit"
                    ? MAX_MONTHLY_LIMIT
                    : monthlyLimit >= MAX_DAILY_LIMIT
                    ? MAX_DAILY_LIMIT
                    : monthlyLimit,
                },
              }}
            />
          )}
          <LoadingButton
            sx={{ marginTop: 4 }}
            loading={loading}
            loadingIndicator="Updating..."
            variant="contained"
            type="submit"
          >
            Update
          </LoadingButton>
        </FormControl>
      </form>
    </Box>
  );
};
export default LimitForm;

import moment from "moment";

export const RequestColumns = (isMobile) => [
  {
    field: "reqDateTime",
    headerName: "Date / Time",
    valueFormatter: ({ value }) =>
      value ? moment(value).format("DD MMM YYYY HH:mm") : "N/A",
    flex: isMobile ? undefined : 0.2,
  },
  {
    field: "reqDesc",
    headerName: "Description",
    flex: isMobile ? undefined : 1,
  },
  // {
  //   field: "reqStatus",
  //   headerName: "Status",
  // },
];

import {
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auditLogsAPI } from "../../apis/logs/LogsApi";
import { getTransactionsApi } from "../../apis/transactions/TransactionsApi";
import { TransactionColumns } from "../../components/DataGrid/Transactions/TransactionsDataGrid";
import Loader from "../../components/Loader/Loader";
import { useAlert } from "../../context/AlertContext";
import { MainBoxProps } from "../../styles/Theme";
import { getUser, handleTokenExpiry } from "../../utils/AuthHelpers";
import { OPERATION_TYPE_ID } from "../../utils/Constants";
import {
  fetchIPAddress,
  getLocationIP,
  getLogsDescription,
  getTransactionsCSV,
} from "../../utils/GeneralHelpers";
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";

// const CustomToolbar = () => {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport
//         csvOptions={{
//           fileName: `Transactions_${moment().format("DD-MM-YYYY-H-mm-ss")}`,
//         }}
//         printOptions={{ disableToolbarButton: true }}
//       />
//     </GridToolbarContainer>
//   );
// };

const Transactions = () => {
  const isTabletOrMobile = useMediaQuery("(max-width: 600px)");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const [user, setUser] = useState();
  const [userIP, setUserIP] = useState();
  const [transactions, setTransactions] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [fromDate, setFromDate] = useState(moment().subtract("15", "days"));
  const [toDate, setToDate] = useState(moment());
  const twoYearsBefore = moment().subtract(2, "year");

  const fetchTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getTransactionsApi({
        customerId: user.customerId,
        userGroupID: user.userGroupID,
        payerAccount: user.payerAccount,
        fromDate: fromDate,
        toDate: toDate,
      });
      setLoading(false);
      if (response.data?.length > 0) {
        setTransactions(response.data);
      } else {
        setAlert({
          type: "error",
          message: "Transactions are empty, no data available!",
        });
        setTransactions([]);
      }
    } catch (e) {
      setLoading(false);
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  }, [user, fromDate, toDate, setAlert, navigate]);

  const getUserIPLocation = useCallback(async () => {
    const userIPInfo = await fetchIPAddress();
    if (userIPInfo) {
      setUserIP(userIPInfo);
    }
  }, []);

  const onSetToDate = (newValue) => {
    if (moment(newValue).isAfter(fromDate)) {
      setToDate(newValue);
      callAuditLogs(OPERATION_TYPE_ID.changeRange, {
        to: newValue,
        from: fromDate,
      });
    } else
      setAlert({
        type: "error",
        message: `To date should be later than ${moment(fromDate).format(
          "DD MMM YYYY"
        )}`,
      });
  };

  const onSetFromDate = (newValue) => {
    if (moment(newValue).isBefore(toDate)) {
      setFromDate(newValue);
      callAuditLogs(OPERATION_TYPE_ID.changeRange, {
        to: toDate,
        from: newValue,
      });
    } else
      setAlert({
        type: "error",
        message: `From date should be earlier than ${moment(toDate).format(
          "DD MMM YYYY"
        )}`,
      });
  };

  const callAuditLogs = useCallback(
    async (operationTypeID, dateRange) => {
      try {
        await auditLogsAPI({
          auditID: 0,
          custID: user.customerId,
          transDesc: getLogsDescription(
            {
              operationTypeID,
              dateRange: {
                from: moment(dateRange.from).format("DD MMM YYYY"),
                to: moment(dateRange.to).format("DD MMM YYYY"),
              },
            },
            user
          ),
          opsTypeID: operationTypeID,
          clickTime: moment(),
          locationIP: getLocationIP(userIP),
        });
      } catch (e) {
      }
    },
    [user, userIP]
  );

  useEffect(() => {
    setUser(getUser());
  }, []);

  useEffect(() => {
    getUserIPLocation();
  }, [getUserIPLocation]);

  useEffect(() => {
    if (user) fetchTransactions();
  }, [user, fromDate, toDate, fetchTransactions]);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <Box {...MainBoxProps}>
      <Loader open={loading} />
      {transactions && (
        <Card>
          <Box sx={{ width: "100%" }} p={2}>
            <Typography variant={"h6"}>Transactions List</Typography>
            <Grid
              container
              spacing={1}
              marginY={2}
              alignItems={"flex-end"}
              // justifyContent={"space-evenly"}
            >
              <Grid item>
                <Box>
                  <Typography variant={"body2"}>From:</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      value={fromDate}
                      onChange={onSetFromDate}
                      inputFormat={"DD MMM YYYY"}
                      disableFuture
                      minDate={twoYearsBefore}
                      renderInput={(params) => (
                        <TextField onKeyDown={onKeyDown} {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Typography variant={"body2"}>To:</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      value={toDate}
                      onChange={onSetToDate}
                      inputFormat={"DD MMM YYYY"}
                      disableFuture
                      minDate={twoYearsBefore}
                      renderInput={(params) => (
                        <TextField onKeyDown={onKeyDown} {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item md={3} />
              <Grid item>
                <CSVLink
                  data={csvData}
                  filename={`Transactions_${moment().format(
                    "DD-MM-YYYY-H-mm-ss"
                  )}`}
                  style={{ color: "#f2f2f2" }}
                  asyncOnClick={true}
                  onClick={(e, done) => {
                    let data = getTransactionsCSV(transactions);
                    if (data.length > 0) {
                      setCSVData(data);
                      callAuditLogs(OPERATION_TYPE_ID.downloadCSV, {
                        to: toDate,
                        from: fromDate,
                      });
                      done(true);
                    } else {
                      setAlert({
                        type: "error",
                        message:
                          "Transactions data is empty, try selecting another date range",
                      });
                      done(false);
                    }
                  }}
                >
                  <Button variant="outlined" endIcon={<Download />}>
                    Download CSV
                  </Button>
                </CSVLink>
              </Grid>
            </Grid>
            <DataGrid
              rows={transactions}
              columns={TransactionColumns(isTabletOrMobile)}
              getRowId={(row) => row.tranStoreID}
              autoHeight
              disableSelectionOnClick
              pagination={null}
              sx={{ marginTop: 1 }}
              rowHeight={isTabletOrMobile ? 100 : 52}
            />
          </Box>
        </Card>
      )}
    </Box>
  );
};
export default Transactions;

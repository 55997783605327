import axios from "axios";
import { BASE_URL, LOCAL_STORAGE_KEYS } from "../../utils/Constants";

let headers = {};
const client = axios.create({
  baseURL: BASE_URL,
  headers,
});
client.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.token);

    if (token) {
      config.headers.Authorization = "Bearer " + JSON.parse(token);
    }
    config.headers.Accept = "application/json";
    config.headers.ContentType = "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default client;

import client from "../config";

export const getTransactionsApi = async ({
  customerId,
  fromDate,
  toDate,
  userGroupID,
  payerAccount,
}) => {
  return await client.post("ListTransactions", {
    custID: customerId,
    userGroupID,
    payerAccount,
    fromDT: fromDate,
    toDT: toDate,
  });
};

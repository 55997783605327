import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const PinReminder = ({ onCancel, onConfirm, loading }) => {
  return (
    <Box p={2} justifyContent={"center"}>
      <Typography>Are you sure you want to send pin reminder?</Typography>
      <Grid container spacing={2} m={1}>
        <Grid item>
          <LoadingButton
            loadingIndicator={"Sending...."}
            loading={loading}
            onClick={onConfirm}
            variant={"contained"}
          >
            Yes, Send Reminder
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            loading={loading}
            onClick={onCancel}
            variant={"contained"}
            loadingIndicator={"No"}
          >
            No
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PinReminder;

import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins-Regular"],
    button: {
      color: "#ffff",
    },
  },
  palette: {
    primary: { main: "#ef8625", light: "#faae3e", dark: "#dd611e" },
    secondary: { main: "#f2f2f2" },
    background: { default: "#f2f2f2", paper: "#ffff" },
    text: { primary: "#fffff", secondary: "#121212", disabled: "#ffffff60" },
  },
});


export const MainBoxProps = { sx: { p: { md: 3, sm: 2, xs: 1 } } };

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import { useState } from "react";
import "./NewUserForm.css";
import {
  MAX_DAILY_LIMIT,
  MAX_MONTHLY_LIMIT,
  MenuProps,
} from "../../../utils/Constants";
import { useAlert } from "../../../context/AlertContext";

const NewUserForm = ({ onSubmit, loading, allProducts }) => {
  const { setAlert } = useAlert();
  const [values, setValues] = useState({
    userName: "",
    userVRN: "",
    products: [],
    dailyLimit: "",
    monthlyLimit: "",
    newPin: "",
  });
  const [showFields, setShowFields] = useState({
    dailyLimit: false,
    pin: false,
  });
  const handleOnChange = (field) => (e) => {
    const input = e.target.value;
    if (field === "products") {
      setValues({
        ...values,
        products: typeof input === "string" ? input.split(",") : input,
        // dailyLimit: Number(values.dailyLimit),
        // monthlyLimit: Number(values.monthlyLimit),
      });
    } else {
      const isUsernameOrVRN = field === "userName" || field === "userVRN";
      if (isUsernameOrVRN && input.length > 0) {
        const regex = /^[a-z0-9]+$/i;
        if (!regex.test(input)) {
          return;
        }
      }
      if (field === "newPin" && input.length > 0) {
        const regex = /^[0-9]+$/i;
        if (!regex.test(input)) {
          return;
        }
      }
      setValues({
        ...values,
        [field]: isUsernameOrVRN ? input.toUpperCase() : input,
      });
    }
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    if (values.monthlyLimit && Number(values.monthlyLimit) <= 0) {
      setAlert({
        type: "error",
        message: "Monthly limit should be greater than zero",
      });
      return;
    }
    let updatedValues = { ...values };
    updatedValues.products = values.products.map((el) => el).join(", ");
    updatedValues.monthlyLimit = Number(values.monthlyLimit);
    updatedValues.dailyLimit =
      showFields.dailyLimit && values.dailyLimit
        ? Number(values.dailyLimit)
        : null;
    updatedValues.newPin = showFields.pin ? values.newPin : "";
    updatedValues.isSetPIN = updatedValues.newPin.length > 0;
    onSubmit(updatedValues);
  };
  const onToggleOptionalFields = (fieldName) => () => {
    setShowFields({ ...showFields, [fieldName]: !showFields[fieldName] });
  };
  return (
    <Box>
      <form onSubmit={onSubmitForm}>
        <FormControl fullWidth className="user-form">
          <TextField
            required
            id="username"
            label="Driver"
            variant="outlined"
            onChange={handleOnChange("userName")}
            value={values.userName}
            inputMode={"text"}
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            required
            id="vrn"
            label="VRN"
            variant="outlined"
            onChange={handleOnChange("userVRN")}
            value={values.userVRN}
            inputProps={{ maxLength: 16 }}
          />
          <Select
            required
            displayEmpty
            id="products-multi-select"
            multiple
            value={values.products}
            input={<OutlinedInput />}
            placeholder={"Products"}
            onChange={handleOnChange("products")}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Typography>Select Products</Typography>;
              }

              return selected.join(", ");
            }}
          >
            <MenuItem disabled value="">
              <em>Select Products</em>
            </MenuItem>
            {allProducts?.map((product) =>
              product.isActive ? (
                <MenuItem key={product.productID} value={product.productName}>
                  {product.productName}
                </MenuItem>
              ) : null
            )}
          </Select>

          <TextField
            required
            id="monthly-limit-field"
            label="Monthly Limit"
            variant="outlined"
            type={"number"}
            InputProps={{
              inputProps: { min: 1, max: MAX_MONTHLY_LIMIT },
            }}
            onChange={handleOnChange("monthlyLimit")}
            value={values.monthlyLimit}
          />

          <Grid container>
            <Grid item alignItems={"center"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showFields.dailyLimit}
                    onChange={onToggleOptionalFields("dailyLimit")}
                  />
                }
                label="Set Daily Limit"
              />
            </Grid>
            <Grid item alignItems={"center"}>
              <FormControlLabel
                checked={showFields.pin}
                control={<Checkbox onChange={onToggleOptionalFields("pin")} />}
                label="Set Pin"
              />
            </Grid>
          </Grid>
          {showFields.pin && (
            <TextField
              id="pin-field"
              label="Pin"
              variant="outlined"
              onChange={handleOnChange("newPin")}
              value={values.newPin}
              inputProps={{ maxlength: 4, minlength: 4 }}
              onInvalid={(e) => {
                e.target.setCustomValidity("");
                if (!e.target.validity.valid) {
                  e.target.setCustomValidity("Pin should be exactly 4 digits");
                }
              }}
            />
          )}
          {showFields.dailyLimit && (
            <TextField
              id="daily-limit-field"
              label="Daily Limit"
              variant="outlined"
              type={"number"}
              onChange={handleOnChange("dailyLimit")}
              value={values.dailyLimit}
              InputProps={{
                inputProps: {
                  min: 1,
                  max:
                  Number(values.monthlyLimit) >= MAX_DAILY_LIMIT
                  ? MAX_DAILY_LIMIT
                  : Number(values.monthlyLimit),
                },
              }}
            />
          )}

          <LoadingButton
            sx={{ marginTop: 4 }}
            loading={loading}
            loadingIndicator="Placing Order..."
            variant="contained"
            type="submit"
          >
            Place Order
          </LoadingButton>
        </FormControl>
      </form>
    </Box>
  );
};
export default NewUserForm;

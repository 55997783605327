import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import logo from "../../assets/images/logo.png";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { loginAPI } from "../../apis/auth/AuthApi.js";
import { emailCustomerAPI } from "../../apis/email/EmailApi";
import Loader from "../../components/Loader/Loader.js";
import {
  fetchToken,
  handleTokenExpiry,
  removeUser,
  storeUser,
} from "../../utils/AuthHelpers.js";
import moment from "moment/moment.js";
import { useAlert } from "../../context/AlertContext";
import { getOTPEmailBody } from "../../utils/EmailHelpers";
import { auditLogsAPI } from "../../apis/logs/LogsApi";
import {
  fetchIPAddress,
  getLocalTime,
  getLocationIP,
} from "../../utils/GeneralHelpers";
import { OPERATION_TYPE_ID } from "../../utils/Constants";
import { useEffect } from "react";

const Login = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [userIP, setUserIP] = useState(null);
  const { setAlert } = useAlert();
  const navigate = useNavigate();

  const getUserIPLocation = useCallback(async () => {
    const userIPInfo = await fetchIPAddress();
    if (userIPInfo) {
      setUserIP(userIPInfo);
    }
  }, [setUserIP]);

  const login = useCallback(
    async (e) => {
      e.preventDefault();
      // call get token api
      try {
        if (!!values.email && !!values.password) {
          setLoading(true);
          await fetchToken();
          // call login api
          const loginResponse = await loginAPI({
            email: values.email,
            password: values.password,
          });
          
          let userResponse =
            loginResponse.data.length > 0 ? loginResponse.data[0] : null;
            await auditLogsAPI({
            auditID: 0,
            custID: userResponse.custID ?? "",
            transDesc: `opsTypeId:${OPERATION_TYPE_ID.login}, login by:${userResponse.empID}-${values.email}`,
            opsTypeID: OPERATION_TYPE_ID.login,
            clickTime: getLocalTime(),
            locationIP: getLocationIP(userIP),
          });
          if (userResponse && userResponse.otpToken !== "0") {
            // send otp to user and take him to otp screen
            let otp = userResponse.otpToken;
            const emailBody = getOTPEmailBody(
              values.email,
              moment().format("llll"),
              otp
            );
            await emailCustomerAPI(emailBody);
              setAlert({
              type: "success",
              message: `OTP code sent to ${values.email}`,
            });
            navigate("/verify", {
              state: { ...userResponse, email: values.email },
            });
          } else {
            // or directly login him if otp verify not required
            if (userResponse.allowLogin === 1) {
              storeUser({
                id: userResponse.empID,
                customerId: userResponse.custID,
                roleId: userResponse.roleID,
                createdAt: userResponse.createDT,
                email: values.email,
              });
              setAlert({ type: "success", message: "Login successfully!" });
              navigate("/");
            } else {
              removeUser();
              throw new Error(
                "Invalid credentials, please login with a correct email and password"
              );
            }
          }
          setLoading(false);
        } else throw new Error("Please enter email and password to login");
      } catch (e) {
        setLoading(false);
        setAlert({
          type: "error",
          message:
            e?.message ??
            "Login failed, please try again with valid credentials",
        });
        handleTokenExpiry(e.response, setAlert, navigate);
      }
    },
    [values, navigate, setAlert, userIP]
  );

  const onChange = (fieldName) => (event) => {
    setValues({ ...values, [fieldName]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onForgotPassword = useCallback(() => {
    navigate("/forgot-password");
  }, [navigate]);

  useEffect(() => {
    getUserIPLocation();
  }, [getUserIPLocation]);

  return (
    <div className="login">
      <Card className="login-box">
        <img src={logo} alt={"Best Petrol & Diesel Pte. Ltd."} className={"logo"} />
        <Loader open={loading} />
        <form onSubmit={login}>
          <TextField
            id="email"
            placeholder="Email or Username"
            variant="outlined"
            value={values.email}
            onChange={onChange("email")}
            className={"text-field"}
            size={"small"}
          />
          <br />
          <OutlinedInput
            id="password"
            placeholder="Password"
            variant="outlined"
            value={values.password}
            onChange={onChange("password")}
            type={values.showPassword ? "text" : "password"}
            size={"small"}
            className={"text-field"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <br />

          <Button
            type="submit"
            variant={"contained"}
            color={"primary"}
            // size={"large"}
            className={"button"}
          >
            Login
          </Button>
          <Button className="button" variant="text" onClick={onForgotPassword}>
            Forgot Password ?
          </Button>
        </form>
      </Card>
      <Typography
        sx={{
          // position: "absolute",
          bottom: 8,
          left: "43vw",
          fontSize: 10,
          marginTop: 5,
        }}
      >
      Best Petrol and Diesel Supply Pte Ltd, 5 Benoi Place, Singapore 629926. Tel: +65 6898 2525
      </Typography>
    </div>
  );
};

export default Login;

import { Button, Card, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import {
  blockUserAPI,
  getUsersAPI,
  modifyUserAPI,
  orderNewUserAPI,
} from "../../apis/users/UserListApi";
import Loader from "../../components/Loader/Loader";
import { getUser, handleTokenExpiry } from "../../utils/AuthHelpers";
import { DataGrid } from "@mui/x-data-grid";
import { MainBoxProps } from "../../styles/Theme";
import {
  UserListColumnsDetailed,
  UserListColumnsShort,
} from "../../components/DataGrid/Users/UsersDataGrid";
import NewUserForm from "../../components/Forms/NewUserForm/NewUserForm";
import CommonModal from "../../components/Modal/Modal";
import moment from "moment";
import { auditLogsAPI } from "../../apis/logs/LogsApi";
import { useAlert } from "../../context/AlertContext";
import { emailCustomerAPI } from "../../apis/email/EmailApi";
import {
  getNewUserEmailBody,
  modifyUsersEmailBody,
} from "../../utils/EmailHelpers";
import { useNavigate } from "react-router-dom";
import {
  OPERATION_TYPE_ID,
  USER_LIST_MODAL_TYPES,
} from "../../utils/Constants";
import {
  fetchIPAddress,
  getLocalTime,
  getLocationIP,
  getLogsDescription,
} from "../../utils/GeneralHelpers";
import { getProductsApi } from "../../apis/products/ProductsApi";
import UserSearch from "../../components/UserSearch/UserSearch";
import { LoadingButton } from "@mui/lab";
import { getBlockReasonAPI } from "../../apis/users/BlockReasonApi";
import BlockUserForm from "../../components/Forms/BlockUserForm/BlockUserForm";

const Users = () => {
  const isTabletOrMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();
  const [user, setUser] = useState();
  const [users, setUsers] = useState();
  const [userIP, setUserIP] = useState(null);
  const [products, setProducts] = useState();
  const [reasons, setReasons] = useState();
  const [detailedView, setDetailedView] = useState(false);
  const [modal, showModal] = useState({
    show: false,
    type: USER_LIST_MODAL_TYPES.order,
  });
  const [confirmModal, showConfirmModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [blockValues, setBlockValues] = useState({
    reason: "",
    reasonDescription: "",
  });

  const getProductLists = useCallback(async () => {
    try {
      const response = await getProductsApi();
      if (response.data) {
        setProducts(response.data);
      } else throw response;
    } catch (e) {
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  }, [navigate, setAlert]);

  const getBlockReasons = useCallback(async () => {
    try {
      const response = await getBlockReasonAPI();
      if (response.data) {
        setReasons(response.data);
      } else throw response;
    } catch (e) {
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  }, [navigate, setAlert]);

  const getUserIPLocation = useCallback(async () => {
    const userIPInfo = await fetchIPAddress();
    if (userIPInfo) {
      setUserIP(userIPInfo);
    }
  }, []);

  const getUserList = useCallback(async () => {
    try {
      setLoading(true);
      if (!user) {
        throw new Error("Could not fetch Account Manager details");
      }
      let { customerId, userGroupID, userGroupName } = user;
      const response = await getUsersAPI({
        custID: customerId,
        userGroupID,
        userGroupName,
      });
      if (response.data?.length > 0) {
        setUsers(response.data);
      } else throw new Error();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  }, [user, navigate, setAlert]);

  const orderUserSubmit = async (values) => {
    const data = {
      ...values,
      newOrderID: 0,
      custID: user.customerId,
      empName: user.email,
      payerAccount: user.payerAccount,
      orderDateTime: getLocalTime(),
      operationTypeID: 10,
    };
    try {
      setLoading(true);
      const response = await orderNewUserAPI(data);
      if (response.status === 201 || response.data) {
        setAlert({
          type: "success",
          message: "New user has been ordered successfully",
        });
        onCloseModal();
        const newUser = response.data;
        // await requestLogsAPI({
        //   reqID: 0,
        //   custID: user.customerId,
        //   reqDesc: `New Card Ordered, ordered by:${user.email}, new Card VRN: ${newUser.userVRN}-${newUser.userName}`,
        //   reqDateTime: moment(),
        //   statusID: 0,
        // });
        try {
          await auditLogsAPI({
            auditID: 0,
            custID: user.customerId,
            transDesc: `opsTypeId:${10}, ordered by:${user.id}-${
              user.email
            }, new user: ${newUser.userVRN}-${newUser.userName}`,
            opsTypeID: 10,
            clickTime: getLocalTime(),
            locationIP: getLocationIP(userIP),
          });
        } catch (e) {
        }
        try {
          const emailBody = getNewUserEmailBody(
            user.email,
            moment().format("llll"),
            newUser
          );
          await emailCustomerAPI(emailBody);
        } catch (e) {
          setAlert({
            type: "success",
            message:
              "Order placed, could not send the order details to your email address, please contact support for any queries",
          });
          handleTokenExpiry(e.response, setAlert, navigate);
        }

        setLoading(false);
      } else throw new Error("An unknow error occured. Please try again");
    } catch (e) {
      setLoading(false);
      setAlert({
        message: e?.message ?? "An unknow error occured. Please try again",
        type: "error",
      });
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  };
  const renderModalContent = () => {
    switch (modal.type) {
      case USER_LIST_MODAL_TYPES.order:
        return (
          <NewUserForm
            onSubmit={orderUserSubmit}
            loading={loading}
            allProducts={products}
          />
        );
      case USER_LIST_MODAL_TYPES.block:
        return (
          <Box p={2} justifyContent={"center"}>
            <UserSearch
              data={users}
              onSelect={setSelectedUser}
              user={selectedUser}
            />
            <BlockUserForm
              reasons={reasons}
              setBlockValues={setBlockValues}
              blockValues={blockValues}
            />
            <LoadingButton
              onClick={onCallBlockOrCancel(OPERATION_TYPE_ID.blockUser)}
              variant={"contained"}
              loading={loading}
              loadingIndicator={"Blocking..."}
            >
              Block Card
            </LoadingButton>
          </Box>
        );
      case USER_LIST_MODAL_TYPES.cancel:
        return (
          <Box p={2} justifyContent={"center"}>
            <UserSearch
              data={users}
              onSelect={setSelectedUser}
              user={selectedUser}
            />
            <BlockUserForm
              reasons={reasons}
              setBlockValues={setBlockValues}
              blockValues={blockValues}
            />
            <LoadingButton
              onClick={onCallBlockOrCancel(OPERATION_TYPE_ID.cancelUser)}
              variant={"contained"}
              loading={loading}
              loadingIndicator={"Cancelling..."}
            >
              Cancel Card
            </LoadingButton>
          </Box>
        );
      default:
        return null;
    }
  };

  const toggleDetailedView = async () => {
    if (!detailedView) {
      // when detailedView = false, a "view details" button will show
      // on that button click, we need to put an audit log
      try {
        auditLogsAPI({
          auditID: 0,
          custID: user.customerId,
          transDesc: getLogsDescription(
            {
              operationTypeID: OPERATION_TYPE_ID.clickUserDetails,
            },
            user
          ),
          opsTypeID: OPERATION_TYPE_ID.clickUserDetails,
          clickTime: getLocalTime(),
          locationIP: getLocationIP(userIP),
        });
      } catch (e) {
        handleTokenExpiry(e.response, setAlert, navigate);
      }
    }
    setDetailedView(!detailedView);
  };

  const onOpenModal = (type) => () =>
    showModal({
      show: true,
      type: type,
    });

  const onClickBlockOrCancelUser = (type) => () => {
    onOpenModal(type)();
  };
  const onCallBlockOrCancel = () => async () => {
    if (!selectedUser) {
      setAlert({ message: "Please select a Card", type: "error" });
      return;
    }
    if (blockValues.reason.length === 0) {
      setAlert({
        message: "Please select a reason for blocking/ cancelling the Card",
        type: "error",
      });
      return;
    }
    showConfirmModal(true);
  };
  const onConfirmBlockOrCancel = async () => {
    const type =
      modal.type === USER_LIST_MODAL_TYPES.block
        ? OPERATION_TYPE_ID.blockUser
        : OPERATION_TYPE_ID.cancelUser;
    try {
      setLoading(true);
      const blockData = {
        custID: user.customerId,
        pid: selectedUser.pid,
        userID: selectedUser.userID,
        payerAccount: user.payerAccount,
        blockReason: blockValues.reason,
      };
      const blockResponse = await blockUserAPI(blockData);
      if (
		blockResponse.data &&
        blockResponse.data?.length > 0 &&
        blockResponse.data[0]?.statusID === 1
      ) {
        const params = {
           modOrderID: 0,
           custID: user.customerId,
           empName: user.email,
           pid: selectedUser.pid,
           userID: selectedUser.userID,
           userName: selectedUser.userName,
           userVRN: selectedUser.userVRN,
           products_Old: selectedUser.products,
           products_New: "",
           monthlyLimit_New: 0,
           monthlyLimit_Old: selectedUser.monthlyLimit,
           dailyLimit_New: 0,
           dailyLimit_Old:
             selectedUser.dailyLimit === "N.A" ? 0 : selectedUser.dailyLimit,
           operationTypeID: type,
           orderModifiedDate: getLocalTime(),
           blockReason: blockValues.reason,
           blockRemarks: blockValues.reasonDescription,
         };
         const response = await modifyUserAPI(params);
         setLoading(false);
         if (response) {
          setAlert({
            message:
            type === OPERATION_TYPE_ID.blockUser
            ? "User blocked successfully"
            : "User cancelled successfully",
            type: "success",
          });
          await getUserList();
          showModal({
            show: false,
            type: USER_LIST_MODAL_TYPES.order,
          });
          showConfirmModal(false);
          setSelectedUser(null);
          
          // await requestLogsAPI({
          //   reqID: 0,
          //   custID: user.customerId,
          //   reqDesc: getLogsDescription({
          //     ...selectedUser,
          //     operationTypeID: type,
          //   }),
          //   reqDateTime: moment(),
          //   statusID: 0,
          // });
          try {
            await auditLogsAPI({
              auditID: 0,
              custID: user.customerId,
              transDesc: getLogsDescription({
                ...selectedUser,
                operationTypeID: type,
              }),
              opsTypeID: type,
              clickTime: getLocalTime(),
              locationIP: getLocationIP(userIP),
            });
          } catch (e) {
            handleTokenExpiry(e.response, setAlert, navigate);
          }
          try {
            const emailBody = modifyUsersEmailBody(
              user.email,
              moment(),
              { ...selectedUser, blockReason: blockValues.reason },
              type
            );
            await emailCustomerAPI(emailBody);
          } catch (e) {
            setAlert({
              type: "success",
              message:
                "User updated, could not send the details to your email due to an error, please contact support for any queries",
            });
            handleTokenExpiry(e.response, setAlert, navigate);
          }
        } else throw new Error("An unknown error ocurred. Please try again");
      } else {
        throw new Error(
          blockResponse.data?.length > 0
            ? blockResponse.data[0]?.msg
            : "Error in block user, please try again"
        );
      }
    } catch (e) {
      setLoading(false);
      showConfirmModal(false);
      setAlert({
        type: "error",
        message: e?.message ?? "An unknown error occured",
      });
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  };

  const onCloseModal = () => {
    showModal({ ...modal, show: false });
    setSelectedUser(null);
    setBlockValues({
      reason: "",
      reasonDescription: "",
    });
  };
  const onCloseConfirmModal = () => {
    showConfirmModal(false);
  };

  useEffect(() => {
    if (user) {
      getUserList();
    }
  }, [user, getUserList]);

  useEffect(() => {
    getBlockReasons();
  }, [getBlockReasons]);

  useEffect(() => {
    getProductLists();
  }, [getProductLists]);

  useEffect(() => {
    getUserIPLocation();
  }, [getUserIPLocation]);

  useEffect(() => {
    setUser(getUser());
  }, []);

  return (
    <Box {...MainBoxProps}>
      <Loader open={loading} />
      {users && (
        <Card>
          <Box sx={{ width: "100%" }} p={2}>
            <Grid container justifyContent={"space-between"} marginY={3}>
              {/* <Grid item>
                <Stack direction={"row"} spacing={1} alignItems={"center"}> */}
              <Typography variant={"h6"}>Cards</Typography>
              {/* </Stack>
              </Grid> */}
              <Grid item>
                <Button variant="outlined" onClick={toggleDetailedView}>
                  {detailedView ? "Show Less" : "Show Details"}
                </Button>
              </Grid>
            </Grid>

            <DataGrid
              rows={users}
              columns={
                detailedView
                  ? UserListColumnsDetailed(products, userIP, isTabletOrMobile)
                  : UserListColumnsShort(products, userIP, isTabletOrMobile)
              }
              getRowId={(row) => row.userID}
              autoHeight
              disableSelectionOnClick
              pagination={null}
              sx={{ marginTop: 1 }}
              rowHeight={isTabletOrMobile ? 100 : 52}
            />
            <Grid container spacing={2} marginY={3}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onOpenModal(USER_LIST_MODAL_TYPES.order)}
                >
                  Order New Card
                </Button>
              </Grid>
              {/* <Grid item>
                <Button
                  variant="contained"
                  onClick={onClickBlockOrCancelUser(
                    USER_LIST_MODAL_TYPES.block
                  )}
                >
                  Block Card
                </Button>
               </Grid> */}
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onClickBlockOrCancelUser(
                    USER_LIST_MODAL_TYPES.cancel
                  )}
                >
                  Cancel Card
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      )}
      <CommonModal
        open={modal.show}
        handleClose={onCloseModal}
        title={modal.type}
      >
        {renderModalContent()}
      </CommonModal>
      <CommonModal
        open={confirmModal}
        handleClose={onCloseConfirmModal}
        title={"Confirm"}
      >
        <Typography>
          Are you sure you want to{" "}
          {modal.type === USER_LIST_MODAL_TYPES.block ? "block" : "cancel"}{" "}
          {selectedUser?.userName ?? "Card"}?
        </Typography>
        <Grid container spacing={2} marginY={1}>
          <Grid item>
            <LoadingButton
              onClick={onConfirmBlockOrCancel}
              variant={"contained"}
              loading={loading}
              loadingIndicator={"Saving..."}
            >
              Yes
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              onClick={onCloseConfirmModal}
              variant={"contained"}
              loading={loading}
            >
              No
            </LoadingButton>
          </Grid>
        </Grid>
      </CommonModal>
    </Box>
  );
};

export default Users;

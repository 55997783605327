import {
  AppBar,
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { Fragment, useCallback, useState } from "react";
import "./Header.css";
import { Email, Logout, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  getUser,
  handleForgotPassword,
  removeToken,
  removeUser,
} from "../../utils/AuthHelpers.js";
import { Stack } from "@mui/system";
import { useAlert } from "../../context/AlertContext";
import { auditLogsAPI } from "../../apis/logs/LogsApi";
import {
  fetchIPAddress,
  getLocalTime,
  getLocationIP,
} from "../../utils/GeneralHelpers";
import { OPERATION_TYPE_ID } from "../../utils/Constants";

const Header = ({ toggleDrawer }) => {
  const isSmallDesktop = useMediaQuery("(max-width: 900px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { setAlert } = useAlert();

  const getUserEmail = useCallback(() => {
    const user = getUser();
    return user ? user.email : "User";
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = useCallback(
    (e) => {
      e.preventDefault();
      removeUser();
      removeToken();
      navigate("/");
    },
    [navigate]
  );

  const getUserIPLocation = useCallback(async () => {
    const userIPInfo = await fetchIPAddress();
    if (userIPInfo) {
      return userIPInfo;
    }
  }, []);

  const changePassword = useCallback(async () => {
    const email = getUser()?.email;
    await handleForgotPassword(
      email,
      setAlert,
      navigate,
      "/change-password",
      "home"
    );
    try {
      const user = getUser();
      const userIP = await getUserIPLocation();
      await auditLogsAPI({
        auditID: 0,
        custID: user.customerId ?? "",
        transDesc: `opsTypeId:${OPERATION_TYPE_ID.changePassword}, Change Password By:${user.id}-${user.email}`,
        opsTypeID: OPERATION_TYPE_ID.changePassword,
        clickTime: getLocalTime(),
        locationIP: getLocationIP(userIP),
      });
    } catch (e) {
    }
  }, [navigate, setAlert, getUserIPLocation]);
  return (
    <div>
      <AppBar sx={{ backgroundColor: (theme) => theme.palette.primary.light }}>
        <Toolbar>
          <Stack
            sx={{ width: "100%" }}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <IconButton
              aria-label="open drawer"
              edge="end"
              onClick={toggleDrawer}
              sx={{ mr: 1, visibility: { md: "hidden", sm: "visible" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bolder",
                letterSpacing: 1.5,
                marginLeft: isSmallDesktop ? 0 : 5,
              }}
            >
              {isSmallDesktop ? "Best Petrol Fleet Hub" : "Best Petrol Fleet Hub"}
            </Typography>
            <Fragment>
              <Tooltip title="Account">
                <Avatar
                  sx={{
                    color: (theme) => theme.palette.primary.light,
                    bgcolor: (theme) => theme.palette.background.paper,
                  }}
                  onClick={handleClick}
                  sizes={""}
                />
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <Email fontSize={"small"} />
                  </ListItemIcon>
                  {getUserEmail()}
                </MenuItem>
                <MenuItem onClick={changePassword}>
                  <ListItemIcon>
                    <Settings fontSize={"small"} />
                  </ListItemIcon>
                  Change Password
                </MenuItem>
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Fragment>
          </Stack>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default Header;

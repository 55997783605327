import { Card, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const CustomerCard = ({ title, dataList = [], image }) => {
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Card sx={{ padding: 2, height: "9rem" }}>
        {/* <Divider /> */}
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h6">{title}</Typography>
            <Stack p={1}>
              {dataList.map((el) => (
                <Typography key={el} variant={"subtitle2"}>
                  {el}
                </Typography>
              ))}
            </Stack>
          </Grid>
          <Grid item>
            {image && (
              <img
                alt={"Account Manager"}
                style={{ width: "auto", height: "7rem" }}
                src={image}
              />
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
export default CustomerCard;

import { Card, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { getAccountManagerAPI } from "../../apis/users/AccountManagerApi";
import { getCustomerDetailsAPI } from "../../apis/users/CustomerApi";
import { getUsersAPI } from "../../apis/users/UserListApi";
import CustomerCard from "../../components/CustomerCard/CustomerCard";
import Loader from "../../components/Loader/Loader";
import { getUser, handleTokenExpiry, storeUser } from "../../utils/AuthHelpers";
import { DataGrid } from "@mui/x-data-grid";
import { UserListColumns } from "../../components/DataGrid/Home/HomeDataGrid";
import { MainBoxProps } from "../../styles/Theme";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../context/AlertContext";
import { fetchFileFromAWS } from "../../utils/GeneralHelpers";

const Home = () => {
  const isTabletOrMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [customer, setCustomer] = useState();
  const [manager, setManager] = useState();
  const [users, setUsers] = useState();

  const storeCustomer = useCallback(async () => {
    let updatedUser = {
      ...user,
      payerAccount: customer.payerAccount,
      userGroupID: customer.userGroupID,
      userGroupName: customer.userGroupName,
    };
    storeUser(updatedUser);
  }, [customer, user]);

  const getCustomerDetails = useCallback(async () => {
    try {
      setLoading(true);
      if (!user || !user.customerId) {
        throw new Error("Could not fetch Customer details");
      }
      const response = await getCustomerDetailsAPI(user.customerId);
      if (response.data) {
        setCustomer(response.data);
      } else throw new Error();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  }, [user, setAlert, navigate]);

  const getManagerDetails = useCallback(async () => {
    try {
      setLoading(true);
      if (!user || !user.customerId) {
        throw new Error("Could not fetch Account Manager details");
      }
      const response = await getAccountManagerAPI(user.customerId);
      if (response.data?.length > 0) {
        let data = response.data[0];
        setManager(data);
        if (data.imgLoc && data.imgName) {
          const managerImage = await fetchFileFromAWS(
            data.imgName,
            data.imgLoc
          );
          setManager({ ...data, image: managerImage });
        }
      } else throw new Error();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  }, [user, setAlert, navigate]);

  const getUserList = useCallback(async () => {
    try {
      setLoading(true);
      if (!customer) {
        throw new Error("Could not fetch Account Manager details");
      }
      let { custId, userGroupID, userGroupName } = customer;
      const response = await getUsersAPI({
        custID: custId,
        userGroupID,
        userGroupName,
      });
      if (response.data?.length > 0) {
        setUsers(response.data);
      } else throw new Error();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  }, [customer, setAlert, navigate]);

  useEffect(() => {
    setUser(getUser());
  }, []);

  useEffect(() => {
    getCustomerDetails();
  }, [getCustomerDetails]);

  useEffect(() => {
    getManagerDetails();
  }, [getManagerDetails]);

  useEffect(() => {
    if (customer) {
      getUserList();
      storeCustomer();
    }
  }, [customer, getUserList, storeCustomer]);

  return (
    <Box {...MainBoxProps}>
      <Loader open={loading} />
      <Grid container spacing={2}>
        {customer && (
          <CustomerCard
            title={"Customer"}
            dataList={[customer.custName, customer.custAddress]}
          />
        )}
        {manager && (
          <CustomerCard
            title={"Account Manager"}
            dataList={[manager.acctMgrName, manager.acctMgrMobile]}
            image={manager.image}
          />
        )}
      </Grid>
      {users && (
        <Card sx={{ marginTop: 2 }}>
          <Box sx={{ width: "100%" }} p={2}>
            <Typography variant={"h6"}>Cards</Typography>
            <DataGrid
              rows={users}
              columns={UserListColumns}
              getRowId={(row) => row.pid}
              autoHeight
              disableSelectionOnClick
              pagination={null}
              sx={{ marginTop: 1 }}
              rowHeight={isTabletOrMobile ? 100 : 52}
            />
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default Home;

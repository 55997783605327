import { useLocation, Navigate, Outlet } from "react-router-dom";
import Main from "../screens/Main/Main";
import { LOCAL_STORAGE_KEYS } from "../utils/Constants";

//const AuthContext = createContext(null);

// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     console.log("in use effect");
//     let storedUser = sessionStorage.getItem(LOCAL_STORAGE_KEYS.user);
//     console.log({ storedUser });
//     if (storedUser) {
//       storedUser = JSON.parse(storedUser);
//       setUser(storedUser);
//     } else setUser(null);
//   }, []);

//   return (
//     <AuthContext.Provider value={{ user, setUser }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

export const RequireAuth = () => {
  const location = useLocation();
  const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.token);
  const storedUser = sessionStorage.getItem(LOCAL_STORAGE_KEYS.user);
  if (!storedUser || !token) {
    return (
      <Navigate
        to={{ pathname: "/login", state: { from: location } }}
        replace
      />
    );
  }

  return <Main />;
};

export const NoRequireAuth = () => {
  const location = useLocation();
  const storedUser = sessionStorage.getItem(LOCAL_STORAGE_KEYS.user);
  if (storedUser) {
    return (
      <Navigate
        to={{ pathname: "/", state: { from: location } }}
        replace
      />
    );
  }

  return <Outlet/>;
};

import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MainBoxProps } from "../../styles/Theme";
import Modal from "../../components/Modal/Modal";
import { OPERATION_TYPE_ID, USER_MODIFY_TYPES } from "../../utils/Constants";
import ProductsForm from "../../components/Forms/ModifyUserForm/ProductsForm";
import { getUser, handleTokenExpiry } from "../../utils/AuthHelpers";
import moment from "moment";
import { modifyUserAPI, sendPinAPI } from "../../apis/users/UserListApi";
import Loader from "../../components/Loader/Loader";
import { useAlert } from "../../context/AlertContext";
import { auditLogsAPI } from "../../apis/logs/LogsApi";
import { emailCustomerAPI } from "../../apis/email/EmailApi";
import { modifyUsersEmailBody } from "../../utils/EmailHelpers";
import {
  getLocalTime,
  getLocationIP,
  getLogsDescription,
} from "../../utils/GeneralHelpers";
import LimitForm from "../../components/Forms/ModifyUserForm/DailyLimitForm";
import PinReminder from "../../components/Forms/ModifyUserForm/PinReminderForm";

const UserDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [modal, showModal] = useState("");
  const [user, setUser] = useState();
  const isSmallDesktop = useMediaQuery("(max-width: 900px)");

  useEffect(() => {
    setUser(getUser());
  }, []);

  const renderRow = (field, value, action, onClick) => {
    return (
      <TableRow key={value}>
        <TableCell>{field}</TableCell>
        <TableCell>{value}</TableCell>
        <TableCell>
          {action && (
            <Button onClick={onClick} variant={"outlined"}>
              {isSmallDesktop ? "Change" : action}
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  };
  const renderForm = () => {
    switch (modal) {
      case USER_MODIFY_TYPES.products:
        return (
          <ProductsForm
            loading={loading}
            onSubmit={onSubmitProducts}
            autoFillValues={data.products}
            allProducts={data.allProducts}
          />
        );
      case USER_MODIFY_TYPES.dailyLimit:
        return (
          <LimitForm
            loading={loading}
            onSubmit={onSubmitDailyLimit}
            autoFillValues={data.dailyLimit}
            monthlyLimit={data.monthlyLimit}
            label={"Daily Limit"}
          />
        );
      case USER_MODIFY_TYPES.monthlyLimit:
        return (
          <LimitForm
            loading={loading}
            onSubmit={onSubmitMonthlyLimit}
            autoFillValues={data.monthlyLimit}
            label={"Monthly Limit"}
          />
        );
      case USER_MODIFY_TYPES.pinReminder:
        return (
          <PinReminder
            loading={loading}
            onConfirm={onSendPinReminder}
            onCancel={closeForm}
          />
        );
      default:
        return null;
    }
  };
  const openForm = (type) => () => {
    showModal(type);
  };
  const closeForm = () => showModal("");

  const onSubmitProducts = (products) => {
    modifyUser({
      products_New: products,
      operationTypeID: OPERATION_TYPE_ID.changeProduct,
    });
  };

  const onSubmitDailyLimit = (dailyLimit) => {
    modifyUser({
      dailyLimit_New: dailyLimit > 0 ? Number(dailyLimit) : null,
      operationTypeID: OPERATION_TYPE_ID.changeDailyLimit,
    });
  };

  const onSubmitMonthlyLimit = (monthlyLimit) => {
    modifyUser({
      monthlyLimit_New: Number(monthlyLimit),
      operationTypeID: OPERATION_TYPE_ID.changeMonthlyLimit,
    });
  };
  const onSendPinReminder = async () => {
    try {
      const sendPinData = {
        custID: user.customerId,
        pid: data.pid,
        userID: data.userID,
        payerAccount: user.payerAccount,
      };
      let pinResponse = await sendPinAPI(sendPinData);
      pinResponse =
        pinResponse.data && pinResponse.data?.length > 0
          ? pinResponse.data[0]
          : null;
      if (pinResponse?.statusID === 1) {
        modifyUser(
          {
            operationTypeID: OPERATION_TYPE_ID.pinReminder,
          },
          pinResponse.msg
        );
      } else throw new Error(pinResponse?.msg);
    } catch (e) {
      showModal("");
      setLoading(false);
      setAlert({
        type: "error",
        message: e?.message ?? "An unknown error occured, please try again",
      });
    }
  };

  const modifyUser = async (newValues, message) => {
    if (data && newValues) {
      let oldMonthlyLimit =
        data.monthlyLimit === "N.A" ? null : Number(data.monthlyLimit);
      let oldDailyLimit =
        data.dailyLimit === "N.A" ? null : Number(data.dailyLimit);
      const params = {
        modOrderID: 0,
        custID: user.customerId,
        empName: user.email,
        pid: data.pid,
        userID: data.userID,
        userName: data.userName,
        userVRN: data.userVRN,
        products_Old: data.products,
        products_New: "",
        monthlyLimit_New: oldMonthlyLimit,
        monthlyLimit_Old: oldMonthlyLimit,
        dailyLimit_New: null,
        dailyLimit_Old: oldDailyLimit,
        orderModifiedDate: getLocalTime(),
        ...newValues,
      };
      try {
        setLoading(true);
        const response = await modifyUserAPI(params);
        if (response.status === 201 || response.data) {
          setAlert({
            type: "success",
            message: message ?? "User updated successfully",
          });
          showModal("");
          const updatedUser = response.data;
          // await requestLogsAPI({
          //   reqID: 0,
          //   custID: user.customerId,
          //   reqDesc: getLogsDescription(newValues, data),
          //   reqDateTime: moment(),
          //   statusID: 0,
          // });
          try {
            await auditLogsAPI({
              auditID: 0,
              custID: user.customerId,
              transDesc: getLogsDescription(newValues, data),
              opsTypeID: newValues.operationTypeID,
              clickTime: getLocalTime(),
              locationIP: getLocationIP(data.userIP),
            });
          } catch (e) {
            handleTokenExpiry(e.response, setAlert, navigate);
          }
          try {
            const emailBody = modifyUsersEmailBody(
              user.email,
              moment(),
              updatedUser,
              newValues.operationTypeID
            );
            await emailCustomerAPI(emailBody);
          } catch (e) {
            setAlert({
              message:
                "User modified, could not send the details to your email address. Please contact support if you have any queries",
              type: "success",
            });
            handleTokenExpiry(e.response, setAlert, navigate);
          }

          setLoading(false);
        } else throw new Error("An unknow error occured. Please try again");
      } catch (e) {
        setLoading(false);
        setAlert({
          type: "error",
          message: e?.message ?? "An unknown error occured",
        });
        handleTokenExpiry(e.response, setAlert, navigate);
      }
    }
  };

  return (
    <Box {...MainBoxProps}>
      <Loader loading={loading} />
      <Card>
        <Box p={2}>
          <Typography variant={"h6"}>Card Details</Typography>
          <TableContainer>
            <Table>
              <TableBody>
                {renderRow("Driver", data.userName)}
                {renderRow("VRN", data.userVRN)}
                {renderRow(
                  "Products",
                  data.products,
                  "Set Products",
                  openForm(USER_MODIFY_TYPES.products)
                )}
                {renderRow(
                  "Daily Limit",
                  data.dailyLimit,
                  "Set Daily Limit",
                  openForm(USER_MODIFY_TYPES.dailyLimit)
                )}
                {renderRow(
                  "Monthly Limit",
                  data.monthlyLimit,
                  "Set Monthly Limit",
                  openForm(USER_MODIFY_TYPES.monthlyLimit)
                )}
                {renderRow(
                  "Pin Set",
                  data.isPinSet ? "Yes" : "No",
                  "Send Pin Reminder",
                  openForm(USER_MODIFY_TYPES.pinReminder)
                )}
                {renderRow(
                  "Expiry Date",
                  moment(data.expiryDate).format("DD MMM YYYY")
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
      <Modal
        open={modal.length > 0}
        title={`Update ${modal}`}
        handleClose={closeForm}
      >
        {renderForm()}
      </Modal>
    </Box>
  );
};

export default UserDetails;

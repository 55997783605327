import moment from "moment";
import {
  generateForgotPasswordOTPAPI,
  getTokenAPI,
} from "../apis/auth/AuthApi";
import { emailCustomerAPI } from "../apis/email/EmailApi";
import { LOCAL_STORAGE_KEYS, TokenParams } from "./Constants";
import { getForgotPasswordEmailBody } from "./EmailHelpers";

export const storeUser = (data) => {
  if (data) sessionStorage.setItem(LOCAL_STORAGE_KEYS.user, JSON.stringify(data));
};
export const getUser = () => {
  let user = sessionStorage.getItem(LOCAL_STORAGE_KEYS.user);
  user = user ? JSON.parse(user) : null;
  return user;
};
export const removeUser = () => {
  sessionStorage.removeItem(LOCAL_STORAGE_KEYS.user);
};
export const removeToken = () => {
  sessionStorage.removeItem(LOCAL_STORAGE_KEYS.token);
};
export const fetchToken = async () => {
  let token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.token);
  if (!token) {
    const tokenResponse = await getTokenAPI(TokenParams);

    if (tokenResponse?.data?.length > 0) {
      let token = JSON.stringify(tokenResponse.data);
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.token, token);
    } else {
    }
  }
};

export const handleTokenExpiry = (response, setAlert, navigate) => {
  if (response && response?.status === 401) {
    setAlert({
      type: "error",
      messgae: "Your session has expired! Please login again",
    });
    removeToken();
    removeUser();
    navigate("/");
  }
};

export const handleForgotPassword = async (
  email,
  setAlert,
  navigate,
  route,
  requestFrom
) => {
  try {
    if (email) {
      let response = await generateForgotPasswordOTPAPI(email);
      if (response && response.data?.length > 0) {
        response = response.data[0];
        if (response.otpToken) {
          const emailBody = getForgotPasswordEmailBody(
            email,
            moment().format("llll"),
            response.otpToken
          );
         await emailCustomerAPI(emailBody);
          setAlert({
            type: "success",
            message: `OTP code sent to ${email}`,
          });
          navigate(route, {
            state: {
              empName: response.empName,
              empID: response.empID,
              requestFrom,
            },
          });
        } else throw new Error();
      }
    } else throw new Error("Please enter your email address");
  } catch (e) {
    setAlert({
      type: "error",
      message:
        e.message ??
        "Could not send an OTP code, please enter a correct email",
    });
    handleTokenExpiry(e.response, setAlert, navigate);
  }
};

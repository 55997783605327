import { Card, Typography } from "@mui/material";
import logo from "../../assets/images/logo.png";

const NotFound = () => (
  <div className="login">
    <Card className="login-box">
      <img src={logo} alt={"Best Petrol & Diesel Pte. Ltd."} className={"logo"} />
      <Typography variant={"h5"}>Not Found</Typography>
      <Typography>The page you tried to access doesn't exist.</Typography>
    </Card>
  </div>
);

export default NotFound;

export const UserListColumns = [
  { field: "userID", headerName: "CardPAN", flex: 0.2 },
  {
    field: "products",
    headerName: "Products",
    flex: 0.4,
  },
  {
    field: "monthlyLimit",
    headerName: "Monthly Limit",
    flex: 0.2,
  },
  {
    field: "userName",
    headerName: "Driver",
    flex: 0.3,
  },
];

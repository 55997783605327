import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useAlert } from "../../context/AlertContext";
import { getUser, handleTokenExpiry } from "../../utils/AuthHelpers";
import Loader from "../../components/Loader/Loader";
import { Card, Typography, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getRequestApi } from "../../apis/requests/RequestsApi";
import { RequestColumns } from "../../components/DataGrid/Requests/RequestDataGrid";
import { MainBoxProps } from "../../styles/Theme";
import { useNavigate } from "react-router-dom";

const Requests = () => {
  const isTabletOrMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();
  const [user, setUser] = useState();
  const [requests, setRequests] = useState([]);

  const fetchRequests = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getRequestApi(user.customerId);
      setLoading(false);
      if (response.data?.length > 0) {
        setRequests(response.data);
      } else {
        setAlert({
          type: "error",
          message: "Requests are empty, no data available!",
        });
      }
    } catch (e) {
      setLoading(false);
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  }, [user, setAlert, navigate]);

  useEffect(() => {
    setUser(getUser());
  }, []);

  useEffect(() => {
    if (user) fetchRequests();
  }, [user, fetchRequests]);

  return (
    <Box {...MainBoxProps}>
      <Loader open={loading} />
      {requests && (
        <Card>
          <Box sx={{ width: "100%" }} p={2}>
            <Typography variant={"h6"}>Requests List</Typography>
            <DataGrid
              rows={requests}
              columns={RequestColumns(isTabletOrMobile)}
              getRowId={(row) => row.reqID}
              pagination={null}
              sx={{ marginTop: 1 }}
              autoHeight
              rowHeight={isTabletOrMobile ? 280 : 52}
              disableSelectionOnClick
            />
          </Box>
        </Card>
      )}
    </Box>
  );
};
export default Requests;

import { FileDownloadOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import { downloadFile } from "../../../utils/GeneralHelpers";

export const InvoicesColumns = (isMobile) => [
  {
    field: "invNo",
    headerName: "Invoice No.",
    flex: isMobile ? undefined : 0.4,
  },
  {
    field: "invDate",
    headerName: "Invoice Date",
    valueFormatter: ({ value }) =>
      value ? moment(value).format("DD MMM YYYY") : "N/A",
    flex: isMobile ? undefined : 0.3,
  },
  {
    field: "invDueDate",
    headerName: "Due Date",
    valueFormatter: ({ value }) =>
      value ? moment(value).format("DD MMM YYYY") : "N/A",
    flex: isMobile ? undefined : 0.3,
  },
  {
    field: "invAmount",
    headerName: "Amount",
    type: "number",
    valueFormatter: ({ value }) => Number(value).toFixed(2),
    flex: isMobile ? undefined : 0.2,
  },
  {
    field: "invDescription",
    headerName: "Description",
    flex: isMobile ? undefined : 0.7,
  },
  {
    headerName: "Download",
    field: "download",
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <IconButton
          onClick={downloadFile(
            params.row.invFilename,
            params.row.invFileLocation,
            params.row.invNo
          )}
        >
          <FileDownloadOutlined />
        </IconButton>
      );
    },
  },
];

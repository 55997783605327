export const BASE_URL = "https://anifwebapi20221109180411.azurewebsites.net/api/";
//export const BASE_URL = "https://localhost:44387/api/";

export const LOCAL_STORAGE_KEYS = {
  token: "token",
  user: "user",
};

export const USER_MODIFY_TYPES = {
  products: "Products",
  dailyLimit: "Daily Limit",
  monthlyLimit: "Monthly Limit",
  pinReminder: "Pin Reminder",
};

export const USER_LIST_MODAL_TYPES = {
  order: "Order New Card",
  block: "Block Card",
  cancel: "Cancel Card",
};

export const TokenParams = {
  password: "Sh!rs@t1o@4",
  username: "@dm!n",
};

export const OTPEmailCC = "";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const OPERATION_TYPE_ID = {
  login: 1,
  changePassword: 2,
  orderNewUser: 10,
  changeProduct: 11,
  changeDailyLimit: 13,
  changeMonthlyLimit: 12,
  pinReminder: 14,
  blockUser: 21,
  cancelUser: 31,
  downloadInvoice: 101,
  clickUserDetails: 102,
  changeRange: 103,
  downloadCSV: 104,
};
export const MAX_MONTHLY_LIMIT = 5000;

export const MAX_DAILY_LIMIT = 2000;
import React, { useCallback } from "react";
import { Email, PhoneInTalk, AccessTime } from "@mui/icons-material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useAlert } from "../../context/AlertContext";

export default function SpeedDialComponent() {
  const { setAlert } = useAlert();

  const copyInfoToClipboard = useCallback(
    (text, title) => () => {
      navigator.clipboard.writeText(text);
      setAlert({ message: `${title} copied to clipboard`, type: "info" });
    },
    [setAlert]
  );

  return (
    <SpeedDial
      ariaLabel="SpeedDial openIcon"
      sx={{ position: "fixed", bottom: 14, right: 15 }}
      icon={<SpeedDialIcon  color={"text"} />}
    >
      <SpeedDialAction
        key={"Phone"}
        icon={<PhoneInTalk color={"primary"} />}
        tooltipTitle={"+65 6898 2525"}
        onClick={copyInfoToClipboard("+65 6515890", "Phone Number")}
      />
      <SpeedDialAction
        key={"Email"}
        icon={<Email color={"primary"} />}
        tooltipTitle={"enquiry@bestpetrol.com.sg"}
        onClick={copyInfoToClipboard("enquiry@bestpetrol.com.sg", "Email")}
      />
      <SpeedDialAction
        key={"Hours"}
        icon={<AccessTime color={"primary"} />}
        tooltipTitle={"Monday - Friday (09:30 - 18:30)"}
      />
    </SpeedDial>
  );
}

import { Card, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getInvoicesApi } from "../../apis/invoices/InvoicesApi";
import { InvoicesColumns } from "../../components/DataGrid/Invoices/InvoicesDataGrid";
import Loader from "../../components/Loader/Loader";
import { useAlert } from "../../context/AlertContext";
import { MainBoxProps } from "../../styles/Theme";
import { getUser, handleTokenExpiry } from "../../utils/AuthHelpers";

const Invoices = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery("(max-width: 600px)");
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();
  const [user, setUser] = useState();
  const [invoices, setInvoices] = useState([]);

  const fetchInvoices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getInvoicesApi(user.customerId);
      setLoading(false);
      if (response.data?.length > 0) {
        setInvoices(response.data);
      } else {
        setAlert({
          type: "error",
          message: "Invoices are empty, no data available!",
        });
      }
    } catch (e) {
      setLoading(false);
      handleTokenExpiry(e.response, setAlert, navigate);
    }
  }, [user, setAlert, navigate]);

  useEffect(() => {
    setUser(getUser());
  }, []);

  useEffect(() => {
    if (user) fetchInvoices();
  }, [user, fetchInvoices]);

  return (
    <Box {...MainBoxProps}>
      <Loader open={loading} />
      {invoices && (
        <Card>
          <Box sx={{ width: "100%" }} p={2}>
            <Typography variant={"h6"}>Invoices List</Typography>
            <DataGrid
              rows={invoices}
              columns={InvoicesColumns(isTabletOrMobile)}
              getRowId={(row) => row.invNo}
              autoHeight
              pagination={null}
              disableSelectionOnClick
              sx={{ marginTop: 1 }}
              rowHeight={isTabletOrMobile ? 100 : 55}
            />
          </Box>
        </Card>
      )}
    </Box>
  );
};
export default Invoices;

import { InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { Stack } from "@mui/system"

const BlockUserForm = (props) => {
    const onChangeValue = (field) => (e) => {
        props.setBlockValues({ ...props.blockValues, [field]: e.target.value })
    }
    return <Stack spacing={1} marginY={1}>
            <InputLabel id="reason-select-label">Select Reason</InputLabel>
            <Select
                labelId="reason-select-label"
                required
                placeholder="Select a reason for blocking / cancelling Card"
                onChange={onChangeValue("reason")}
                value={props.blockValues.reason}
            >
                {
                    props.reasons?.map(reason => reason.isActive ? <MenuItem
                        key={reason.blkReasonID}
                        value={reason.blockReasonDesc}
                    >{reason.blockReasonDesc}</MenuItem> : null)
                }
            </Select>
            <TextField label={"Reason Description"}
                onChange={onChangeValue("reasonDescription")}
                placeholder={"Describe the reason in detail"}
                value={props.blockValues.reasonDescription}
                multiline
            ></TextField>
        </Stack>
}
export default BlockUserForm
import { Close } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  maxHeight: "90vh",
  overflowY: "scroll",
};

const CommonModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal"
      aria-describedby="modal-component"
    >
      <Box sx={{ ...style, width: { md: "50vw", xs: "90vw" } }}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"h6"}>{props.title}</Typography>
          <IconButton sx={{}} onClick={props.handleClose}>
            <Close />
          </IconButton>
        </Grid>
        <Divider />
        <Box p={1}>{props.children}</Box>
      </Box>
    </Modal>
  );
};
export default CommonModal;

import moment from "moment";
import { OPERATION_TYPE_ID, OTPEmailCC } from "./Constants";

export const getOTPEmailBody = (email, time, otp) => {
  return {
    toAdd: email,
    ccAdd: OTPEmailCC,
    emSubject: "Login OTP - FleetHub Best Petrol",
    emhtmlBody: `<p>Dear Customer,</p>\n<p>You have requested for a One-Time Password (OTP) on <b>${time}</b></p>\n<p>${
      otp ? `Your OTP is <b>${otp}</b>.` : ""
    }</p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`,
    emTextBody: `<p>Dear Customer,</p>\n<p>You have requested for a One-Time Password (OTP) on <mark>[PLACEHOLDER TIME].</mark></p>\n<p>${
      otp ? `Your OTP is <mark>${otp}</mark>.` : ""
    }</p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`,
  };
};

export const getForgotPasswordEmailBody = (email, time, otp) => {
  return {
    toAdd: email,
    ccAdd: OTPEmailCC,
    emSubject: "Change Password Request - FleetHub Best Petrol",
    emhtmlBody: `<p>Dear Customer,</p>\n<p>You have requested for a change password on <b>${time}</b></p>\n<p>${
      otp ? `Your OTP is <b>${otp}</b>.` : ""
    }</p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`,
    emTextBody: `<p>Dear Customer,</p>\n<p>You have requested for a One-Time Password (OTP) on <mark>[PLACEHOLDER TIME].</mark></p>\n<p>${
      otp ? `Your OTP is <mark>${otp}</mark>.` : ""
    }</p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`,
  };
};

export const getChangePasswordEmailBody = (email, time) => {
  return {
    toAdd: email,
    ccAdd: OTPEmailCC,
    emSubject: "Password Changed - FleetHub Best Petrol",
    emhtmlBody: `<p>Dear Customer,</p>\n<p>Your password was changed on <b>${time}</b></p>\n<p>If you did not change your password on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`,
    emTextBody: `<p>Dear Customer,</p>\n<p>Your password was changed on <b>${time}</b></p>\n<p>If you did not change your password on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`,
  };
};
export const getNewUserEmailBody = (email, time, data) => {
  return {
    toAdd: email,
    ccAdd: OTPEmailCC,
    emSubject: "New Card Order - FleetHub Best Petrol",
    emhtmlBody: `<p>Dear Customer,</p>\n<p>You have requested for a new Card on <b>${time}
    </b></p>\n<p>The order details are:</p>\n<p>Driver: <b>${data.userName}
    </b></p>\n<p>VRN: <b>${data.userVRN}
    </b></p>\n <p>Products: <b>${data.products}
    </b></p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`,
    emTextBody: `<p>Dear Customer,</p>\n<p>You have requested for a new Card on <b>${time}
    </b></p>\n<p>The order details are:</p>\n<p>Name: <b>${data.userName}
    </b></p>\n<p>VRN: <b>${data.userVRN}
    </b></p>\n <p>Products: <b>${data.products}
    </b></p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`,
  };
};
export const getEmailBodyByOpsType = (time, data, opsTypeID) => {
  switch (opsTypeID) {
    case OPERATION_TYPE_ID.changeProduct:
      return `<p>Dear Customer,</p>\n<p>You have modified products for the CardPAN - ${data.userID} on <b>${time}
      </b></p>\n<p>The Card details are:</p>\n<p>Driver: <b>${data.userName}
      </b></p>\n<p>New Products: <b>${data.products_New ?? "N.A"}
      </b></p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`;
    case OPERATION_TYPE_ID.changeDailyLimit:
      return `<p>Dear Customer,</p>\n<p>You have modified the daily limit for the CardPAN - ${data.userID} on <b>${time}
      </b></p>\n<p>The Card details are:</p>\n<p>Driver: <b>${data.userName}
      </b></p>\n<p>New Daily Limit: <b>${data.dailyLimit_New ?? "N.A"}
      </b></p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`;
    case OPERATION_TYPE_ID.changeMonthlyLimit:
      return `<p>Dear Customer,</p>\n<p>You have modified the monthly limit for the CardPAN - ${data.userID} on <b>${time}
      </b></p>\n<p>The Card details are:</p>\n<p>Driver: <b>${data.userName}
      </b></p>\n<p>New Daily Limit: <b>${data.monthlyLimit_New ?? "N.A"}
      </b></p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`;
    case OPERATION_TYPE_ID.blockUser:
      return `<p>Dear Customer,</p>\n<p>You have made a request to block the following Card on <b>${time}
      </b></p>\nDriver: <b>${data.userName}
      </b></p>\n<p>CardPAN: <b>${data.userID}
      </b></p>\n<p>Block Reason: <b>${data.blockReason}
      </b></p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`;
    case OPERATION_TYPE_ID.cancelUser:
      return `<p>Dear Customer,</p>\n<p>You have made a request to cancel the following Card on <b>${time}
      </b></p>\nDriver: <b>${data.userName}
      </b></p>\n<p>CardPAN: <b>${data.userID}
      </b></p>\n<p>Cancel Reason: <b>${data.blockReason}
      </b></p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`;
    case OPERATION_TYPE_ID.pinReminder:
      return `<p>Dear Customer,</p>\n<p>You have made a request to send pin reminder for the following Card on <b>${time}
      </b></p>\nDriver: <b>${data.userName}
      </b></p>\n<p>CardPAN: <b>${data.userID}
      </b></p>\n<p>If you did not make this request on the above-mentioned date/time, or if you have any queries about your account, please contact our helpdesk at +65 6898 2525 (phone) or enquiry@bestpetrol.com.sg (email). Our customer service officers will be happy to assist you.</p>\n\n<p>Yours Sincerely,</p>\n<p>Best Petrol & Diesel Supply Pte. Ltd.</p>\n<p>Please do not reply to this email.</p>`;
    default:
      return "";
  }
};
export const modifyUsersEmailBody = (email, time, data, opsTypeID) => {
  const body = getEmailBodyByOpsType(
    moment(time).format("llll"),
    data,
    opsTypeID
  );
  return {
    toAdd: email,
    ccAdd: OTPEmailCC,
    emSubject: "Card Modified - FleetHub Best Petrol",
    emhtmlBody: body,
    emTextBody: body,
  };
};

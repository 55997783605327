import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import SidebarNav from "../../components/Sidebar/Sidebar";
import SpeedDialComponent from "../../components/SpeedDial/SpeedDial";
import "./Main.css";

const Main = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <SidebarNav />
      <Box sx={{ flexGrow: 1, p: 0, marginTop: 8 }}>
        <Outlet />
      </Box>
      {/* <Typography
        sx={{
          position: "fixed",
          bottom: 8,
          left: { md: 255, sm: 20 },
          fontSize: 10,
        }}
      >
        © Aniket Solutions Pte LTD. All Rights Reserved.
      </Typography> */}
      <SpeedDialComponent />
    </Box>
  );
};
export default Main;

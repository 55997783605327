import moment from "moment";
import { getFormattedTime } from "../../../utils/GeneralHelpers";

export const TransactionColumns = (isMobile) => [
  { field: "userID", headerName: "CardPAN" },
  {
    field: "productDesc",
    headerName: "Product",
    flex: isMobile ? undefined : 0.2,
  },
  {
    field: "transDesc",
    headerName: "Description",
    flex: isMobile ? undefined : 0.4,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    type: "number",
    valueFormatter: ({ value }) => Number(value ?? 0).toFixed(2),
  },
  {
    field: "transDateTime",
    headerName: "Date",
    valueFormatter: ({ value }) =>
      value ? moment(value).format("D MMM YYYY") : "N/A",
    flex: isMobile ? undefined : 0.2,
  },
  {
    field: "transTime",
    headerName: "Time",
    valueFormatter: ({ value }) => getFormattedTime(value),
    // valueFormatter: ({ value }) => {
    //   let time = value.length === 7 ? `0${value}` : value;
    //   let date = moment().format("YYYY-MM-DD") + `T${time}`;
    //   return moment(date).format("hh:mm A");
    // },
    flex: isMobile ? undefined : 0.2,
  },
  {
    field: "grossPrice",
    headerName: "Gross Price",
    type: "number",
    // valueFormatter: ({ value }) => value.toFixed(2)
    valueFormatter: ({ value }) => Number(value ?? 0).toFixed(2),
    flex: isMobile ? undefined : 0.2,
  },
];

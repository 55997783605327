import { Button, Card, TextField, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import Loader from "../../components/Loader/Loader";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../context/AlertContext";
import "./ForgotPassword.css";
import { fetchToken, handleForgotPassword } from "../../utils/AuthHelpers";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading] = useState(false);
  const { setAlert } = useAlert();
  const navigate = useNavigate();

  const onChange = (e) => {
    setEmail(e.target.value);
  };
  const onClick = useCallback(
    async (e) => {
      e.preventDefault();
      await fetchToken();
      await handleForgotPassword(
        email,
        setAlert,
        navigate,
        "/forgot-password-change"
      );
    },
    [navigate, email, setAlert]
  );
  return (
    <div className="forgot-pwd">
      <Card className="forgot-pwd-box">
        <img src={logo} alt={"Best Petrol & Diesel Pte. Ltd."} className={"logo"} />
        <Loader open={loading} />
        <Typography>
          Enter your email, we will send an OTP code.
        </Typography>
        <form onSubmit={onClick}>
          <TextField
            id="email"
            placeholder="Enter Email"
            variant="outlined"
            value={email}
            onChange={onChange}
            className={"text-field"}
            size={"small"}
          />
          <Button
            type="submit"
            variant={"contained"}
            color={"primary"}
            className={"button"}
          >
            Send Verification Code
          </Button>
        </form>
      </Card>
      <Typography
        sx={{
          // position: "absolute",
          bottom: 8,
          left: "43vw",
          fontSize: 10,
          marginTop: 5,
        }}
      >
        
      </Typography>
    </div>
  );
};
export default ForgotPassword;

import { CloseOutlined } from "@mui/icons-material";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const UserSearch = (props) => {
  const onSearch = (e, value) => {
    let user = props.data.find((user) => user.userName === value);
    props.onSelect(user);
  };

  return (
    <Box>
      <Autocomplete
        freeSolo
        id="search-user-autocomplete"
        disableClearable
        options={props.data?.map((option) => option.userName)}
        onChange={onSearch}
        clearIcon={<CloseOutlined />}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search by Driver"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      {props.user && (
        <Box
          marginY={2}
          p={1}
          sx={{
            backgroundColor: (theme) => theme.palette.secondary.main,
            borderRadius: 1,
          }}
        >
          <Typography>CardPAN: {props.user.userID ?? "N/A"} </Typography>
          <Typography>Driver: {props.user.userName ?? "N/A"} </Typography>
          <Typography>VRN: {props.user.userVRN ?? "N/A"} </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UserSearch;

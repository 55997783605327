import { createContext, useContext, useEffect, useState } from "react";

const AlertContext = createContext(null);

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    message: "",
    type: "info",
  });

  useEffect(() => {
    if (alert.message?.length > 0) {
      setTimeout(
        () =>
          setAlert({
            message: "",
            type: "info",
          }),
        3500
      );
    }
  }, [alert.message]);

  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

//import moment from "moment";
import { Link } from "react-router-dom";

export const UserListColumnsShort = (products, userIP, isMobile) => [
  { field: "userID", headerName: "CardPAN" },
  {
    field: "products",
    headerName: "Products",
    flex: isMobile ? undefined : 0.5,
  },
  {
    field: "monthlyLimit",
    headerName: "Monthly Limit",
    flex: isMobile ? undefined : 0.2,
  },
  {
    field: "userName",
    headerName: "Driver",
    flex: isMobile ? undefined : 0.3,
  },
  {
    headerName: "Action",
    field: "actions",
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <Link
          to={`/users/${params.row.userID}`}
          state={{ ...params.row, allProducts: products, userIP }}
          style={{ color: "#ef8625" }}
        >
          View Card
        </Link>
      );
    },
  },
];

export const UserListColumnsDetailed = (products, userIP, isMobile) => [
  { field: "userID", headerName: "CardPAN" },
  {
    field: "userName",
    headerName: "Driver",
    flex: isMobile ? undefined : 0.4,
  },
  {
    field: "userVRN",
    headerName: "VRN",
    flex: isMobile ? undefined : 0.4,
  },
  {
    field: "products",
    headerName: "Products",
    flex: isMobile ? undefined : 0.9,
  },
  {
    field: "monthlyLimit",
    headerName: "Monthly Limit",
    flex: isMobile ? undefined : 0.3,
  },
  {
    field: "dailyLimit",
    headerName: "Daily Limit",
  },
  // {
  //   field: "expiryDate",
  //   headerName: "Expiry Date",
  //   valueFormatter: ({ value }) =>
  //     value ? moment(value).format("D MMM YYYY") : "N/A",
  // },
  // {
  //   headerName: "Action",
  //   field: "actions",
  //   sortable: false,
  //   disableClickEventBubbling: true,

  //   renderCell: (params) => {
  //     return (
  //       <Link
  //         to={`/users/${params.row.userID}`}
  //         state={{ ...params.row, allProducts: products, userIP }}
  //         style={{ color: "#ef8625" }}
  //       >
  //         View User
  //       </Link>
  //     );
  //   },
  // },
];

import { Routes, Route } from "react-router-dom";
import { NoRequireAuth, RequireAuth } from "../context/AuthContext";
import Home from "../screens/Home/Home";
import Login from "../screens/Login/Login";
import NotFoundPage from "../screens/NotFound/NotFound";
import VerifyOTP from "../screens/VerifyOTP/VerifyOTP";
import { Fragment } from "react";
import Alerts from "../components/Alerts/Alerts";
import { useAlert } from "../context/AlertContext";
import ForgotPassword from "../screens/ForgotPasswordOTP/ForgotPassword";
import ChangePassword from "../screens/ForgotPasswordChange/ChangePassword";
import Invoices from "../screens/Invoices/Invoices";

import HomeIcon from "@mui/icons-material/Home";
import UsersIcon from "@mui/icons-material/Group";
import TransactionIcon from "@mui/icons-material/ReceiptLong";
import InvoiceIcon from "@mui/icons-material/Paid";
import RequestsIcon from "@mui/icons-material/DocumentScanner";
import Requests from "../screens/Requests/Requests";
import Transactions from "../screens/Transactions/Transactions";
import Users from "../screens/Users/Users";
import UserDetails from "../screens/Users/UserDetails";

export const ProtectedRoutes = [
  {
    path: "/",
    title: "Home",
    element: <Home />,
    icon: <HomeIcon />,
    subpath: false,
  },
  {
    path: "/users",
    title: "Cards",
    element: <Users />,
    icon: <UsersIcon />,
    subpath: false,
  },
  {
    path: "/users/:id",
    title: "Card Details",
    element: <UserDetails />,
    icon: <UsersIcon />,
    subpath: true,
  },
  {
    path: "/transactions",
    title: "Transactions",
    element: <Transactions />,
    icon: <TransactionIcon />,
    subpath: false,
  },
  {
    path: "/invoices",
    title: "Invoices",
    element: <Invoices />,
    icon: <InvoiceIcon />,
    subpath: false,
  },
  {
    path: "/requests",
    title: "Requests",
    element: <Requests />,
    icon: <RequestsIcon />,
    subpath: false,
  },
  {
    path: "/change-password",
    title: "Change Password",
    element: <ChangePassword />,
    icon: null,
    subpath: true,
  },
];

export const UnprotectedRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/verify",
    element: <VerifyOTP />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/forgot-password-change",
    element: <ChangePassword />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

const AppRoutes = () => {
  const { alert } = useAlert();

  return (
    <Fragment>
      <Routes>
        <Route path={"/"} element={<RequireAuth />}>
          <Route index element={<Home />} />
          {ProtectedRoutes.map((route) => (
            <Route
              key={route.title}
              path={route.path}
              element={route.element}
            />
          ))}
        </Route>
        <Route element={<NoRequireAuth />}>
          {UnprotectedRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
      </Routes>
      {alert.message && <Alerts message={alert.message} type={alert.type} />}
    </Fragment>
  );
};

export default AppRoutes;

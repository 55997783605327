import client from "../config/index.js";

export const getTokenAPI = async ({ username, password }) => {
  return await client.post("token", {
    APIUserName: username,
    APIPassword: password,
  });
};

export const loginAPI = async ({ email, password }) => {
  return await client.post("AuthloginReturns", {
    empName: email,
    empPwd: password,
  });
};

export const verifyOTPAPI = async ({ empID, empName, otpToken }) => {
  return await client.post("LoginOTPVerifies", {
    empID: empID,
    otpToken: otpToken,
    empName: empName,
  });
};

export const generateForgotPasswordOTPAPI = async (email) => {
  return await client.post("GenCustOTPs", {
    empName: email,
  });
};

export const changePasswordAPI = async ({
  empID,
  otpToken,
  empName,
  empPwd,
}) => {
  return await client.post("ChangePwds", {
    empID,
    otpToken,
    empName,
    empPwd,
  });
};

import { Button, Card, TextField, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import Loader from "../../components/Loader/Loader";
import logo from "../../assets/images/logo.png";
import "./VerifyOTP.css";
import { verifyOTPAPI } from "../../apis/auth/AuthApi";
import { useLocation, useNavigate } from "react-router-dom";
import { storeUser } from "../../utils/AuthHelpers.js";
import { useAlert } from "../../context/AlertContext";

const VerifyOTP = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state;

  const onChange = (e) => {
    setCode(e.target.value);
  };
  const verify = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const verifyResponse = await verifyOTPAPI({
          empID: user.empID,
          empName: user.email,
          otpToken: code,
        });
        setLoading(false);
        //console.log({ verifyResponse });
        if (verifyResponse?.data[0]?.statusID === 1) {
          setAlert({
            type: "success",
            message: "OTP Code verified, logged in successfully!",
          });
          const userData = {
            id: user.empID,
            customerId: user.custID,
            roleId: user.roleID,
            createdAt: user.createDT,
            email: user.email,
          };
          storeUser(userData);
          navigate("/");
        } else
          throw new Error(
            verifyResponse?.data[0]?.msg ?? "OTP Code verification failed"
          );
      } catch (e) {
        setLoading(false);
        setAlert({
          type: "error",
          message:
            e.message ??
            "OTP Code verification failed. Please try again with a valid code",
        });
      }
    },
    [user, navigate, code, setAlert]
  );
  return (
    <div className="verify">
      <Card className="verify-box">
        <img src={logo} alt={"Best Petrol & Diesel Pte. Ltd."} className={"logo"} />
        <Loader open={loading} />
        <form onSubmit={verify}>
          <TextField
            id="email"
            placeholder="Enter OTP code from your email"
            variant="outlined"
            value={code}
            onChange={onChange}
            className={"text-field"}
            size={"small"}
          />
          <Button
            type="submit"
            variant={"contained"}
            color={"primary"}
            className={"button"}
          >
            Verify OTP
          </Button>
        </form>
      </Card>
      <Typography
        sx={{
          bottom: 8,
          left: "43vw",
          fontSize: 10,
          marginTop: 5,
        }}
      >
        Best Petrol and Diesel Supply Pte Ltd, 5 Benoi Place, Singapore 629926. Tel: +65 6898 2525
      </Typography>
    </div>
  );
};
export default VerifyOTP;

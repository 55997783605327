import moment from "moment";
import { getFileDownloadAPI } from "../apis/download/DownloadApi";
import { auditLogsAPI, getIPAddressAPI } from "../apis/logs/LogsApi";
import { getUser } from "./AuthHelpers";
import { OPERATION_TYPE_ID } from "./Constants";

export const getLogsDescription = (newValues, userData) => {
  const opsTypeID = newValues.operationTypeID;
  switch (opsTypeID) {
    case OPERATION_TYPE_ID.changeProduct:
      return `Change Products, CardPAN: ${userData.userID}, Driver: ${userData.userName}, New Products: ${newValues.products_New}`;
    
    case OPERATION_TYPE_ID.changeDailyLimit:
      return `Change Daily Limit, CardPAN: ${userData.userID}, Driver: ${userData.userName}, New Daily Limit: ${newValues.dailyLimit_New}`;
    
    case OPERATION_TYPE_ID.changeMonthlyLimit:
      return `Change Monthly Limit, CardPAN: ${userData.userID}, Driver: ${userData.userName}, New Monthly Limit: ${newValues.monthlyLimit_New}`;
    
    case OPERATION_TYPE_ID.blockUser:
      return `Block Card, CardPAN: ${newValues.userID}, Driver: ${newValues.userName}`;
    
    case OPERATION_TYPE_ID.cancelUser:
      return `Cancel Card, CardPAN: ${newValues.userID}, Driver: ${newValues.userName}`;
    
    case OPERATION_TYPE_ID.pinReminder:
      return `Pin Reminder Sent, CardPAN: ${userData.userID}, Driver: ${userData.userName}`;
    
    case OPERATION_TYPE_ID.clickUserDetails:
      return `Clicked Card Details, Clicked By User Id: ${userData.id}, Email: ${userData.email}`;
    
    case OPERATION_TYPE_ID.downloadInvoice:
      return `Invoice Downloaded, Download By User Id: ${userData.id}, Email: ${userData.email}, Invoice ID: ${newValues.id}`;
    
    case OPERATION_TYPE_ID.downloadCSV:
      return `CSV Downloaded, Download By User Id: ${userData.id}, Email: ${userData.email}, Date Range: From:${newValues.dateRange?.from ?? "N/A"} , To:${newValues.dateRange?.to ?? "N/A"}`;
    
    case OPERATION_TYPE_ID.changeRange:
      return `Transactions - Date Range Changed , By User Id: ${userData.id}, Email: ${userData.email}, Date Range: From:${newValues.dateRange?.from ?? "N/A"} , To:${newValues.dateRange?.to ?? "N/A"}`;
    default:
      return "";
  }
};
export const getLocationIP = (userIP) => {
  return userIP
    ? `${userIP.ip},${userIP.coordinates},${userIP.country}`
    : "Geolocation-API-Failed";
};
export const fetchIPAddress = async () => {
  try {
    const response = await getIPAddressAPI();
    return {
      coordinates: `lat:${response.data.latitude}, long:${response.data.longitude}`,
      city: response.data.city,
      country: response.data.country_name,
      ip: response.data.IPv4,
    };
  } catch (e) {
    return null;
  }
};
export const downloadFile = (file, bucket, invoiceId) => async () => {
  const downloadableFile = await fetchFileFromAWS(file, bucket);
  let tempLink = document.createElement("a");
  tempLink.href = downloadableFile;
  tempLink.setAttribute("download", file);
  tempLink.click();
  URL.revokeObjectURL(downloadableFile);
  tempLink.remove();

  try {
    const user = getUser();
    const userIP = await fetchIPAddress();
    await auditLogsAPI({
      auditID: 0,
      custID: user.customerId,
      transDesc: getLogsDescription(
        {
          operationTypeID: OPERATION_TYPE_ID.downloadInvoice,
          id: invoiceId,
        },
        user
      ),
      opsTypeID: OPERATION_TYPE_ID.downloadInvoice,
      clickTime: moment(),
      locationIP: getLocationIP(userIP),
    });
  } catch (e) {
  }
};
export const fetchFileFromAWS = async (file, bucket) => {
  try {
    const response = await getFileDownloadAPI(file, bucket);
    const blob = new Blob([response.data], {
      type: "image/png",
    });
    const downloadableFile = URL.createObjectURL(blob);
    return downloadableFile;
  } catch (e) {
  }
};
export const getFormattedTime = (value) => {
  if (!value) return "N/A";
  let time = value.length === 7 ? `0${value}` : value;
  let date = moment().format("YYYY-MM-DD") + `T${time}`;
  return moment(date).format("hh:mm A");
};
export const getTransactionsCSV = (data) => {
  return data.map((item) => {
    return {
      CardPAN: item.userID,
      Product: item.productDesc,
      Description: item.transDesc,
      Quantity: Number(item.quantity ?? 0).toFixed(2),
      Date: item.transDate
        ? moment(item.transDate).format("D MMM YYYY")
        : "N/A",
      Time: getFormattedTime(item.transTime),
      GrossPrice: Number(item.grossPrice ?? 0).toFixed(2),
    };
  });
};
export const getLocalTime = () => moment().format("YYYY-MM-DDTHH:mm:ss");
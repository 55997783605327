import "./App.css";
import "./styles/Global.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/Theme";
import { AlertProvider } from "./context/AlertContext";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    const handleKeyDown = e => {
      if(e.keyCode === 123) {
        e.preventDefault()

        return false;
     }
     if(e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
      e.preventDefault()

        return false;
     }
     if(e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
      e.preventDefault()

        return false;
     }
     if(e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
      e.preventDefault()

        return false;
     }
     if(e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
      e.preventDefault()

        return false;
     }
    }
    document.addEventListener('contextmenu', handleContextmenu)
    document.addEventListener('keydown', handleKeyDown)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu);
        document.removeEventListener('keydown', handleKeyDown)
    }
}, [ ])
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </AlertProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

import axios from "axios";
import client from "../config";

export const requestLogsAPI = async (data) => {
  return await client.post("RequestLogs", data);
};

export const auditLogsAPI = async (data) => {
  return await client.post("AuditLogs", data);
};

export const getIPAddressAPI = async () => {
  return await axios.get(
  	`https://geolocation-db.com/json/`
  );
};
